@import 'fonts/fonts.css';

/* MSDOS 6.22 CSS Theme */
:root {
    /*--font:       var(--fontArcology);*/
    /*--font:       var(--fontChronoType);*/
    /*--font:       var(--fontIshmeria);*/
    /*--font:       var(--fontMMRock9);*/
    /*--font:       var(--fontNDS12);*/
    /*--font:       var(--fontQuarlow);*/
    /*--font:       var(--fontReactor7);*/
    /*--font:       var(--fontStingray);*/
    /*--font:       var(--fontPxPlusChipPlayer);*/
    /*--font:       var(--fontTahoma83);*/
    --charH:      19px;
    --charHneg:  -19px;
    --charW1:      8px;
    --charW2:     16px;
    --charW2neg: -16px;
    --charW3:     24px;
    --charW3neg: -24px;
    --railH:       3px;
    --knobH:       11px;
    --knobW:       16px;

    --rowHeight:  var(--charH);

    --clickable:  #ff0;
    --active:     #800;
    --button:     #66d;
    --selected:   #66d6;
    --focus:      #00f;
    --background: #008;
    --shadow:     #005;
    --neutral4:   #fff;
    --neutral3:   #ccc;
    --neutral2:   #aaa;
    --neutral1:   #777;
    --neutral0:   #000;

}

.theme-mint {
    --clickable:  #0fa;
    --active:     #800;
    --button:     #187;
    --selected:   #1366;
    --focus:      #136;
    --background: #043;
    --shadow:     #005;
    --neutral4:   #fff;
    --neutral3:   #cdc;
    --neutral2:   #8ba;
    --neutral1:   #587;
    --neutral0:   #000;

    .BrowseList-row.even { background-color: #f0f0f008; }
}

[data-theme="msdos"] {
    --font: var(--fontPxPlusChipPlayer);

    --message-box-background: var(--button);
    --message-box-color: var(--clickable);
    --message-box-info-text-color: var(--clickable);
    --message-box-inner-border: 1px solid var(--clickable);
    --message-box-box-shadow: var(--charW1) var(--charW1) var(--shadow);
    --msdos-box-shadow: var(--charW1) var(--charW1) var(--shadow);
    --hide-mtime-column-below-width: 800px;

    /* chunky DOS animation */
    .message-box { transition: all 250ms steps(5); }
    .toast-box-outer { transition: all 150ms steps(4); }
}

[data-theme="winamp"] {
    --font: var(--fontTahoma);
    @media (max-resolution: 96dpi) {
        --font: var(--fontTahoma83);
    }

    --gold: #a3946a; /* gold */
    --lightGold: #ecce7a; /* light gold */
    /*#3e3c60; !* dark purple *!*/
    /*#1e1e2f; !* darker purple *!*/
    /*#0000c6; !* blue *!*/
    /*#d9e4ed; !* button highlight *!*/
    /*#bdced6; !* button light *!*/
    /*#7b8494; !* button dark *!*/
    /*#4a5a6b; !* button shadow *!*/
    --clickable: #0f0;
    --neutral2: #eef9;
    --button: #bdced6;
    --focus: #0000c6;
    --charH: 16px;
    --charW1: 6px;
    --rowHeight: 17px;
    --railH: 10px;
    --knobH: 10px;
    --knobW: 29px;
    --form-field-height: 22px;
    --button-height: 22px;
    --field-box-shadow: inset -1px -1px #fff, inset 1px 1px grey, inset -2px -2px #dfdfdf, inset 2px 2px #0a0a0a;

    --background: linear-gradient(45deg, #1e1e2f 0%, #3e3c60 35%, #1e1e2f 100%);

    --message-box-background: var(--background);
    --message-box-inner-background: #fff;
    --message-box-color: #eefb;
    --message-box-border: 2px groove #3e3c60;
    --message-box-info-text-font: 11px/16px Menlo, Consolas, monospace;
    --message-box-inner-border: 2px groove #3e3c60;
    --message-box-info-text-color: #000;
    --message-box-info-text-background: #fff;


    a:active  { background: var(--focus); }

    /* don't allow text selection, except for input */
    *:not(input, .info-text) { user-select: none; }
    .App { background-image: linear-gradient(45deg, #1e1e2f 0%, #3e3c60 35%, #1e1e2f 100%); }
    .App-main .App-main-content-area:not(.settings) { background-color: #000; }
    .App-main .App-main-content-area .BrowseList-row.Song-now-playing {
        a { color: #fff; }
        background-color: transparent; color: #fff;
    }
    .App-main .App-main-content-area .BrowseList-row.Song-now-playing.BrowseList-row-selected { background: var(--focus); }
    button { border: none; }
    .Favorite-button { color: #0f05; font-family: sans-serif; font-size: 10px; }
    .tab, button.box-button, .Search-clearButton {
        color: #000;
        height: var(--button-height);
        box-shadow:
            1px 1px 0 inset #adb5c6,
        -1px -1px 0 inset #4a5a6b,
        2px 2px 0 inset #fff,
        -3px -3px 2px inset #7b8494
        ;
        padding: 0 var(--charW2);
        border-radius: 0;
        background: #bdced6;
        border: 1px solid #151520;
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;
        &:focus {
            outline: 1px dotted #000;
            outline-offset: -4px;
        }
        &:active {
            box-shadow:
                0 0 16px inset #0006,
                1px 1px 0 inset #adb5c6,
                -1px -1px 0 inset #4a5a6b,
                2px 2px 0 inset #fff,
                -3px -3px 2px inset #7b8494;
        }
    }
    .Search-clearButton::before { content: 'Clear'; }
    .tab { padding: 0 15px; }
    button[disabled] { background-color: var(--button); color: #4a5a6b; }
    .box-button .inline-icon { background-color: #000; }
    [disabled] .inline-icon { background-color: #4a5a6b; }

    .App-main-content-area:not(.settings) { border-right: 2px ridge #3e3c60; }
    .tab-container { padding-bottom: 16px; border-bottom: 2px groove #1e1e2f; }
    .AppFooter { border-top: 2px ridge #3e3c60; height: 170px; }
    .AppFooter-art { border: 2px ridge #3e3c60; position: relative; top: -40px; }
    .BrowseList-colDir { display: none; }
  @media screen and (max-width: 500px) { .BrowseList-colMtime { display: none } }
  @container (max-width: 400px) { .BrowseList-colMtime { display: none } }
    .box-button .icon-prev { background: url(images/winamp/prev.png) center no-repeat; mask-image: none; }
    .box-button .icon-next { background: url(images/winamp/next.png) center no-repeat ; mask-image: none; }
    .box-button .icon-play { background: url(images/winamp/play.png) center no-repeat; mask-image: none; }
    .box-button .icon-pause { background: url(images/winamp/pause.png) center no-repeat; mask-image: none; }
    .box-button .icon-rewind { background: url(images/winamp/rewind.png) center no-repeat; mask-image: none; }
    .box-button .icon-forward { background: url(images/winamp/forward.png) center no-repeat; mask-image: none; }

    .Slider-rail {
        border-top: 2px solid #0004;
        border-left: 2px solid #0004;
        border-bottom: 1px solid #fff4;
        border-right: 1px solid #fff4;
        background: #1e1e2f80;
    }
    .Slider-knob {
        border: 0;
        background-image: url(images/winamp/handle-wide.png);
    }
    .Visualizer {
        border-top: 2px groove #3E3C5F;
        border-left: 2px groove #3E3C5F;
    }
    .VisualizerParams-label { width: 70px; }
    .Visualizer-options {
        padding: 12px;
        text-align: unset;
        background-image: linear-gradient(0deg, #0008, #0003 60%);
        border-bottom: 2px groove #3e3c5f;
        & >div { display: flex; align-items: center; height: var(--form-field-height); }
    }
    .Visualizer-overlay { top: 92px; }
    .App-main-content-area.settings {
        background: #0003; color: #eefb;
        width: 300px;
        min-width: 300px;
        padding: var(--charH) var(--charW2);
        /*background: silver; color: black; !* Windows 98 *!*/
    }
    .Settings h3 { border-bottom: 2px groove #1e1e2f; padding-bottom: 4px; }
    .PlayerParams-param:not(.PlayerParams-group) {
        margin: 4px 0;
        height: var(--form-field-height);
    }
    .PlayerParams-param input[type=range] { margin-right: 6px; }
    .PlayerParams-group-title { height: var(--form-field-height); display: flex; align-items: center; }
    .App-voice-label {
        width: 96px;
        max-width: 96px;
        display: flex;
        align-items: center;
        height: var(--form-field-height);
    }
    .message-box { width: 720px; }
    .message-box-footer { padding-top: 10px; } /* because the button is 22px instead of 16px height */
    .toast-box-outer { transition: all 150ms; padding: 0; }
    .toast-box-outer[hidden] { top: -85px; }
    .toast-box { height: 85px; color: #000; border-bottom: 2px groove #8888; }
    .toast-info { background: linear-gradient(90deg, #ffecab 0%, #ffe58e 100%); }
    .toast-error { background: linear-gradient(15deg, #571515 0%, #751d1d 50%, #571515 100%); }

    a:focus,
    button:focus,
    input:focus,
    input[type=checkbox]:focus,
    input[type=radio]:focus,
    input[type=range]:focus
    {
        outline: none;
    }

    input[type=range] {
        margin: 0;
        -webkit-appearance: none;
        background: transparent;
        vertical-align: bottom;
        height: var(--charH); /* Firefox */
        width: 128px; /* Firefox */
    }
    input[type=range]::-webkit-slider-runnable-track {
        height: 6px;
        border: 0;
        background: #fa0;
        box-shadow: 0 2px 2px inset #0008;
        /*box-shadow: 1px 1px 0 inset #1e1e2f, -2px -1px 0 inset #747a8c;*/
        border-radius: 6px;
        border-top: 1px solid #000;
        border-left: 1px solid #000;
        border-bottom: 1px solid #747a8c;
        border-right: 1px solid #747a8c;
        margin: var(--charW1) 0;
    }
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-image: url(images/winamp/handle-mini.png);
        border: 0;
        height: 11px;
        width: 14px;
        margin-top: -3px;
    }
    input[type=range]:focus::-webkit-slider-runnable-track { background: #ff0; }
    input[type=range]:focus::-webkit-slider-runnable-track { background: #ff0; }
    input[type=range]:active {
        background: unset;
        &::-webkit-slider-thumb {
        background-image: url(images/winamp/handle-mini.png);
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    select {
        width: 200px;
        box-shadow: var(--field-box-shadow);
        height: var(--form-field-height);
        box-sizing: border-box;
    }



    input[type=text] {
        color: #000;
        background-color: #fff;
        border: 1px solid #7f9db9;
        padding: 1px 4px;
    }
    input[type=radio] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0 6px 0 0;
        background: 0;
        position: relative;
        border: none;
        height: 12px;
        width: 12px;
    }

    input[type=radio]:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0px;
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0H4v1H2v1H1v2H0v4h1v2h1V8H1V4h1V2h2V1h4v1h2V1H8V0z' fill='gray'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 1H4v1H2v2H1v4h1v1h1V8H2V4h1V3h1V2h4v1h2V2H8V1z' fill='%23000'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 3h1v1H9V3zm1 5V4h1v4h-1zm-2 2V9h1V8h1v2H8zm-4 0v1h4v-1H4zm0 0V9H2v1h2z' fill='%23DFDFDF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 2h-1v2h1v4h-1v2H8v1H4v-1H2v1h2v1h4v-1h2v-1h1V8h1V4h-1V2z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 2h4v1h1v1h1v4H9v1H8v1H4V9H3V8H2V4h1V3h1V2z' fill='%23fff'/%3E%3C/svg%3E")
    }

    input[type=radio]:active:before {
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0H4v1H2v1H1v2H0v4h1v2h1V8H1V4h1V2h2V1h4v1h2V1H8V0z' fill='gray'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 1H4v1H2v2H1v4h1v1h1V8H2V4h1V3h1V2h4v1h2V2H8V1z' fill='%23000'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 3h1v1H9V3zm1 5V4h1v4h-1zm-2 2V9h1V8h1v2H8zm-4 0v1h4v-1H4zm0 0V9H2v1h2z' fill='%23DFDFDF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 2h-1v2h1v4h-1v2H8v1H4v-1H2v1h2v1h4v-1h2v-1h1V8h1V4h-1V2z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 2h4v1h1v1h1v4H9v1H8v1H4V9H3V8H2V4h1V3h1V2z' fill='silver'/%3E%3C/svg%3E")
    }

    input[type=radio]:checked:after {
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        top: 4px;
        left: 4px;
        position: absolute;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='4' height='4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 0H1v1H0v2h1v1h2V3h1V1H3V0z' fill='%23000'/%3E%3C/svg%3E")
    }

    input[type=checkbox] {
        display: inline-block;
        height: 13px;
        width: 13px;
        box-shadow: var(--field-box-shadow);
        background: #fff
    }

    input[type=checkbox]:checked::after {
        content: "";
        position: absolute;
        height: 7px;
        width: 7px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='7' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 0H6v1H5v1H4v1H3v1H2V3H1V2H0v3h1v1h1v1h1V6h1V5h1V4h1V3h1V0z' fill='%23000'/%3E%3C/svg%3E");
        bottom: 2px;
        left: 0px
    }

    input[type=checkbox] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0 6px 0 0;
        background: #fff;
        border: none;
        position: relative;
    }

    input[type=range]:focus,
    input[type=radio]:focus+label,
    input[type=checkbox]:focus+label {
        outline: 1px dotted #fff8
    }

    input[type=checkbox]:active {
        background: silver
    }

    input[type=checkbox]:checked:after {
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        position: absolute;
        top: 3px;
        left: 3px;
    }

    select {
        height: 21px; /* Hard coded for Windows 98 style dropdown */
        border: none;
        background-color: #fff;
        box-sizing: border-box;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        padding: 3px 32px 3px 4px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 0H0v16h1V1h14V0z' fill='%23DFDFDF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 1H1v14h1V2h12V1H2z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 17H0v-1h15V0h1v17z' fill='%23000'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 1h-1v14H1v1h14V1z' fill='gray'/%3E%3Cpath fill='silver' d='M2 2h12v13H2z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 6H4v1h1v1h1v1h1v1h1V9h1V8h1V7h1V6z' fill='%23000'/%3E%3C/svg%3E");
        background-position: top 2px right 2px;
        background-repeat: no-repeat;
        border-radius: 0;
    }

    select:focus {
        outline: none;
        color: #fff;
        background-color: navy;
    }

    select:focus option {
        color: #000;
        background-color: #fff;
    }

    select:active {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0h16v17H0V0zm1 16h14V1H1v15z' fill='gray'/%3E%3Cpath fill='silver' d='M1 1h14v15H1z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 7H5v1h1v1h1v1h1v1h1v-1h1V9h1V8h1V7z' fill='%23000'/%3E%3C/svg%3E");
    }

    option {
        font-family: "Pixelated MS Sans Serif",Arial;
        font-size: 11px;
    }

    /* scrollbars */
    .App-main-content-and-settings {
        ::-webkit-scrollbar {
            width: 15px;
        }

        ::-webkit-scrollbar:horizontal {
            height: 15px;
        }

        ::-webkit-scrollbar-corner {
            background: #555
        }

        ::-webkit-scrollbar-track {
            background-color: #292940;
            background: url(images/winamp/track.png) 0 0 repeat;
        }

        ::-webkit-scrollbar-thumb {
            background-color: red;
            background: url(images/winamp/thumb-top.png) 0 0 no-repeat, url(images/winamp/thumb-bottom.png) 0 100% no-repeat, url(images/winamp/thumb-mid.png) 0 0 repeat;
        }
    }
}

.grid { /* utility class for testing the character grid */
    background-image:
        repeating-linear-gradient(
            to right, #fff1 0px, #fff1 1px, #fff0 1px, #fff0 var(--charW1)),
        repeating-linear-gradient(
            to bottom, #fff2 0px, #fff2 1px, #fff0 1px, #fff0 var(--charH));
}

.App * {
    font: var(--font);
    -webkit-font-smoothing: antialiased; /* supports Japanese characters better than 'none' */
    -moz-osx-font-smoothing: grayscale; /* Firefox */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    text-decoration: none;
}

[data-theme="msdos"] {
*::selection {
    background: rgba(170, 170, 170, 0.99);
    color: #000088;
}

a:focus,
button:focus,
input:focus,
input[type=checkbox]:focus,
input[type=radio]:focus,
input[type=range]:focus {
    outline: none;
    background-color: var(--focus);
}
a:active  { background: var(--active); }
}

input,
button,
select,
textarea {
    font: inherit;
}

/* Basic elements */

body {
    background: var(--background);
    overflow: hidden;
    color: var(--neutral2);
}

/* Margins in one direction (bottom) */
h1, h2, h3, h4, h5, h6 {
    color: var(--neutral4);
    margin: 0 0 var(--charH) 0;
    padding: 0;
    font-weight: normal;
}

p { margin: 0 0 var(--charH) 0; }

a {
    text-decoration: none;
    color: var(--clickable);
    cursor: pointer;
}

a:visited { color: var(--clickable); }

[data-theme="msdos"] {
    /* Scrollbars */
    body { /* Firefox */
        scrollbar-color: var(--clickable) var(--button);
        scrollbar-width: thin;
    }

    ::-webkit-scrollbar {
        height: var(--charW1);
        width: var(--charW1);
        background: var(--button);
    }

    ::-webkit-scrollbar-thumb {
        background: var(--clickable);
        -webkit-border-radius: 0;
    }

    /* Form elements */

button {
    border: none;
    border-radius: 0;
    padding: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: var(--clickable);
}
select {
    border: none;
    background: none;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 0 var(--charW1);
    color: var(--clickable);
    background-color: var(--button);
    background-image: /* A little down-arrow */
            linear-gradient(60deg, transparent 50%, var(--clickable) 50%),
            linear-gradient(120deg, var(--clickable) 50%, transparent 50%);
    background-position: calc(100% - var(--charW1)*1.5) 5px, calc(100% - var(--charW1)) 5px;
    background-size: calc(var(--charW1)*0.5) 12px, calc(var(--charW1)*0.5) 12px;
    background-repeat: no-repeat;
    padding-right: var(--charW2);
    max-width: 240px;
}
select option                { font-size: 14px; }
.Song-now-playing .Favorite-button { color: var(--background); }
  @media screen and (max-width: 800px) { .BrowseList-colMtime { display: none } }
  @container (max-width: 800px) { .BrowseList-colMtime { display: none } }
button:active   { background: var(--active); }
button:disabled { background: var(--neutral1); color: var(--neutral3); }
.box-button     { background: var(--button); box-shadow: var(--msdos-box-shadow); white-space: pre; padding: 0 var(--charW2); }
.box-button:active { box-shadow: none; }

    .tab {
        padding: 0 15px 0 15px;
        background: transparent;
        border: 1px solid var(--clickable);
        border-bottom: none;
        border-radius: 3px 3px 0 0;
        box-sizing: border-box;
        height: var(--charH);
    }

    .tab-selected {
        border-bottom: 1px solid var(--background);
        background: var(--background);
        margin-bottom: -1px;
        height: calc(var(--charH) + 1px);
    }
    input[type=checkbox],
    input[type=radio] {
        appearance: none;
        background: transparent;
        border: 0;
        border-radius: 0;
        margin: 0 var(--charW1) 0 0;
        color: var(--clickable);
        white-space: pre;  /* prevent iOS Safari text wrapping */
    }

    input[type=checkbox]:before         { content: '[ ]'; }
    input[type=checkbox]:checked:before { content: '[️x]'; }

    input[type=radio]:before            { content: '( )'; }
    input[type=radio]:checked:before    { content: '(•)'; }
  input[type=text]::selection                 { background-color: #FFFFFF; }
  input[type=text]::-webkit-input-placeholder { color: var(--neutral1) }
}
input[type=text] {
    border: 0;
    border-radius: 0;
    background-color: var(--neutral2);
    color: var(--background);
    padding: 0 var(--charW1);
    width: 256px;
    height: var(--charH); /* Firefox */
}

.inline { display: inline-block; margin-right: var(--charW1);   }
.inline:last-child { margin-right: 0; }
input[type=range] {
    margin: 0;
    -webkit-appearance: none;
    background: transparent;
    vertical-align: bottom;
    height: var(--charH); /* Firefox */
    width: 128px; /* Firefox */
}

input[type=range]::-webkit-slider-runnable-track {
    height: var(--railH);
    border: 1px solid var(--clickable);
    margin: var(--charW1) 0;
}
input[type=range]::-moz-range-track { /* Firefox */
    height: var(--railH);
    border: 1px solid var(--clickable);
    margin: var(--charW1) 0;
    box-sizing: border-box;
}

input[type=range]:disabled::-webkit-slider-runnable-track { border-color: var(--neutral2); }
input[type=range]:disabled::-moz-range-track { border-color: var(--neutral2); } /* Firefox */
input[type=range]:disabled::-webkit-slider-thumb { background: var(--neutral2); }
input[type=range]:disabled::-moz-range-thumb { background: var(--neutral2); } /* Firefox */
input[type=range]:active::-webkit-slider-thumb { background: var(--clickable); }
input[type=range]:active::-moz-range-thumb { background: var(--clickable); } /* Firefox */

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: var(--button);
    border: 1px solid var(--clickable);
    height: var(--knobH);
    margin-top: calc(var(--knobH) / -2);
    width: var(--charW2);
}
input[type=range]::-moz-range-thumb { /* Firefox */
    -moz-appearance: none;
    background: var(--button);
    border: 1px solid var(--clickable);
    border-radius: 0;
    height: var(--knobH);
    margin-top: calc((var(--charH) - var(--knobH)) / -2);
    width: var(--charW2);
}

/* Component overrides */

body,#root {
    margin: 0;
}

.App {
    padding: 0 var(--charW2);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-variant-numeric: tabular-nums;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.AppHeader {
    padding: var(--charH) var(--charW2);
    margin: 0 var(--charW2neg);
    flex-grow: 0;
}

.AppHeader-subtitle {
    margin: var(--charH) 0 0 0;
}

.AppHeader-title, .AppHeader-title:visited, .AppHeader-title:link {
    color: var(--neutral4);
}

.AppHeader-byline {
    float: right;
}

.App-main {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
    margin: 0 var(--charW2neg);
    margin-top: -1px; /* shrink by 1px for top border of tabs */
}

.App-main-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 1px; /* prevents content width priority */
    margin-right: -1px; /* hide the right border of the content area */
}

.App-main-content-and-settings {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-grow: 1;
}

.App-main-content-area {
    flex-grow: 1;
    /*display: flex;*/
    flex-direction: column;
    min-width: 0;
    padding: var(--charH) var(--charW2) 0 var(--charW2);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border-right: 1px solid var(--clickable);
    container-type: inline-size;
}
.App-main-content-area:focus { /* I have no idea why this became focusable */
    background-color: initial;
}

.ReactVirtualized__Grid:focus {
    outline: none;
}

.App-main-content-area.settings {
    width: 360px;
    min-width: 360px;
    flex-grow: 0;
}

.AppFooter {
    border-top: 1px solid var(--clickable);
    margin: 0 var(--charW2neg);
    flex-shrink: 0;
    height: 210px;
    display: flex;
}

.AppFooter-art {
    width: 210px;
    height: 210px;
    background-color: var(--neutral0);
    object-fit: scale-down;
    image-rendering: unset;
    border-left: 1px solid var(--clickable);
    flex-shrink: 0;
}
@media screen and (max-width: 600px) {
    .AppFooter-art, .AppFooter-shuffle, .AppFooter-repeat { display: none; }
}

[data-theme="msdos"], [data-theme="winamp"] {
    button.AppFooter-forward, button.AppFooter-rewind { padding: 0 var(--charW1); }
}

.AppFooter-main {
    flex-grow: 1;
    overflow: auto;
}

/*
 Required due to strange flexbox/padding behavior in Safari.
 https://stackoverflow.com/questions/42802717
*/
.AppFooter-main-inner {
    padding: var(--charH) var(--charW2);
}

.AppFooter-settings {
    border-left: 1px solid var(--clickable);
    padding: var(--charH) var(--charW2);
    max-width: 360px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.App-voice-label {
    width: calc(var(--charW1) * 14);
    white-space: nowrap;
    overflow-x: hidden;
}

.AppFooter-top-row {
    display: flex;
    align-items: center;
    gap: var(--charW1);
}

.Slider {
    height: var(--charH);
    padding: 0;
    cursor: pointer;
    position: relative;
    width: calc(100% - var(--knobW));
    box-sizing: border-box;
    border: 0;
}

.Slider-rail {
    width: calc(100% + var(--knobW));
    height: var(--railH);
    background: none;
    position: absolute;
    top: calc((var(--charH) - var(--railH)) / 2);
    box-sizing: border-box;
    border: 1px solid var(--clickable);
}

.Slider-knob {
    height: var(--knobH);
    width: var(--knobW);
    top: calc((var(--charH) - var(--knobH)) / 2);
    margin: 0;
    border-radius: 0px;
    background-color: var(--button);
    border: 1px solid var(--clickable);
    position: absolute;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
}

.Browse-topRow { display: flex; justify-content: space-between; margin-bottom: var(--charH); }

.BrowseList-row { display: flex; align-items: center; }
/* Selected */
.ReactVirtualized__Grid:focus .BrowseList-row-selected {
    background-color:  var(--focus);
}
/* Playing */
.App-main .App-main-content-area .Song-now-playing {
    background-color: var(--button);
    color: var(--neutral3);
}
[data-theme="msdos"] {
/* Selected AND Playing */
.ReactVirtualized__Grid:focus .BrowseList-row-selected.Song-now-playing {
    box-shadow: 0 0 1px 1px inset var(--focus);
    outline: 1px solid var(--focus);
}
.BrowseList-row:has(a:hover) { color: var(--neutral4); }
 }
.BrowseList-colName { overflow: hidden; flex-grow: 1; display: flex; align-items: center; }
.BrowseList-colName a { white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.BrowseList-colDir, .BrowseList-colSize { flex-shrink : 0; margin-left: calc(var(--charW1) * 2); }
.BrowseList-colCount { flex-shrink : 0; margin-left: var(--charW1); width: calc(var(--charW1) * 5); text-align: right; }
.BrowseList-colMtime { text-align: right; text-transform: uppercase; white-space: nowrap; min-width: calc(var(--charW1) * 12); }
.BrowseList-colSize { text-align: right; text-transform: uppercase; white-space: nowrap; min-width: calc(var(--charW1) * 10); }

.DirectoryLink-dim { color: var(--neutral2); }

.PlayerParams h3 { margin-top: 0; }
.PlayerParams-param { display: flex; flex-wrap: wrap; align-items: center; }
.PlayerParams-param input { margin-right: var(--charW1); }
.PlayerParams-group-title { width: 100%; }
.PlayerParams-voiceList {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(var(--charW1) * -1);
    max-width: calc(var(--charW1) * 60);
}
.PlayerParams-label { flex-shrink: 0; width: calc(var(--charW1) * 14); }
.PlayerParams-group { margin-bottom: var(--charH); }

.SongDetails {}
.SongDetails-title {color: var(--neutral4);}
.SongDetails-subtitle, .SongDetails-subtitle, .SongDetails-filepath {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}
.SongDetails-filepath a {color: var(--neutral2);}

.Search-label { display: flex; align-items: center; gap: var(--charW1); }
.Search-input { width: calc(var(--charW1) * 20); }
.Search-clearButton { white-space: pre; }
.Search-clearButton::before { content: ' x'; }
.Search-clearButton::after  { content: ' '; }
.Search-resultsLabel { white-space: nowrap; }

button.Favorite-button:active { color: var(--clickable); }
button.Favorite-button.isFavorite { color: var(--clickable); }
button.Favorite-button {
    cursor: pointer;
    color: var(--button);
    background: initial;
    padding: 0 var(--charW1);
}

/*.Song-now-playing .Trash-button { background: var(--button); }*/
.Trash-button {
    cursor: pointer;
    background: initial;
}

.Visualizer { position: relative; display: flex; flex-direction: column; align-items: flex-end }
.Visualizer-overlay { position: absolute; top: calc(4 * var(--charH)); }
.Visualizer-options { padding: var(--charH) var(--charW2) 0; text-align: right; }
.Visualizer-toggle { position: fixed; top: var(--charH); right: var(--charW2); }
.Visualizer-analyzer { display: block; image-rendering: pixelated; }
.Visualizer-spectrogram { display: block; flex-grow: 1; flex-shrink: 0; min-height: 800px; image-rendering: pixelated; }
.Visualizer-analyzer, .Visualizer-spectrogram {
    margin-left: 1px; /* Don't cover the right border of App-main-inner */
}

.TimeSlider { flex-grow: 1; margin: var(--charH) 0; }
.TimeSlider-labels, .VolumeSlider-labels { display: flex; flex-direction: row; justify-content: space-between; }

.VolumeSlider { flex-shrink: 0; margin-top: var(--charH); margin-bottom: var(--charH); margin-left: var(--charW2); }
@media screen and (max-width: 600px) {
    .VolumeSlider { display: none; }
}

.inline-icon {
    vertical-align: bottom;
    display: inline-block;
    background-color: var(--clickable);
    mask-repeat: no-repeat;
    mask-position: center;
    height: var(--charH);
    flex-shrink: 0;
}
[disabled] .inline-icon { background-color: var(--neutral2); }
.dim-icon      { background-color: var(--neutral2); }
.icon-play     { width: 24px; mask-image: url(images/play.png); }
.icon-pause    { width: 24px; mask-image: url(images/pause.png); }
.icon-prev     { width: 24px; mask-image: url(images/prev.png); }
.icon-next     { width: 24px; mask-image: url(images/next.png); }
.icon-rewind   { width: 24px; mask-image: url(images/rewind.png); }
.icon-forward  { width: 24px; mask-image: url(images/forward.png); }
.icon-folder   { width: 24px; mask-image: url(images/folder.png); }
.icon-trash    { width: 24px; mask-image: url(images/trash.png); }
.icon-copy     { width: 24px; mask-image: url(images/copy.png); }
.icon-download { width: 16px; mask-image: url(images/download.png); }
.icon-repeat   { width: 16px; mask-image: url(images/repeat.png); }
.icon-shuffle  { width: 16px; mask-image: url(images/shuffle.png); }
.icon-chip     { width: 24px; mask-image: url(images/chip.png); }

.tab-container {
    border-bottom: 1px solid var(--clickable);
    padding: 0 var(--charW2);
    display: flex;
    gap: var(--charW1);
}

.tab-settings { margin-left: auto; margin-right: 0; }

.toast-box-outer {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: start;
    justify-content: center;
    height: 100%;
    width: 100%;
    pointer-events: none;
    top: 0;
    left: 0;
    padding: 0 var(--charW2);
    box-sizing: border-box;
}
.toast-box {
    padding: var(--charH) var(--charW2);
    box-shadow: var(--msdos-box-shadow);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: start;
    overflow: hidden;
    max-height: 100%;
    pointer-events: all;
    width: 100%;
    height: calc(4 * var(--charH));
}
.toast-box-outer[hidden] {
    top: calc(-4 * var(--charH));
    visibility: hidden;
}
.toast-box .message {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    max-height: 100%;
    overflow: hidden;
    padding-right: var(--charW1);
    text-overflow: ellipsis;
    white-space: initial;
}
.toast-error {
    background-color: var(--active);
    color: var(--neutral4);
}
.toast-info {
    background-color: var(--button);
    color: var(--neutral3);
}

/* message-box is used by Info box and DropMessage component. */
.message-box-outer {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: round(calc(100% + 0px), 2px);;
    pointer-events: none;
    top: 0;
    left: 0;
    padding: var(--charH) var(--charW2);
    box-sizing: border-box;
}

.message-box-outer[hidden] {
    visibility: hidden;
}

.message-box {
    background: var(--message-box-background);
    padding: var(--charH) var(--charW2);
    box-shadow: var(--message-box-box-shadow);
    outline: var(--message-box-border);
    box-sizing: border-box;
    display: flex;
    visibility: visible;
    overflow: hidden;
    max-height: 100%;
    pointer-events: all;
    flex-direction: column;
    width: calc(90 * var(--charW1));
    height: calc(40 * var(--charH));
}

.message-box[hidden] {
    width: 0;
    height: 0;
    visibility: hidden;
}

.message-box[hidden] .message-box-inner {
    overflow: hidden;
}

.message-box-inner {
    color: var(--message-box-color);
    outline: var(--message-box-inner-border);
    padding: var(--charH) var(--charW2);
    overflow: auto;
}

.message-box-inner.info-text {
    white-space: pre;
    font: var(--message-box-info-text-font);
    color: var(--message-box-info-text-color);
    background: var(--message-box-info-text-background);
}

.message-box-footer {
    display: flex;
    flex-direction: row-reverse;
    align-content: end;
    justify-content: space-between;
    padding-top: var(--charH);
}

.drop-message {
    height: calc(9 * var(--charH) + 1px);
    width: calc(60 * var(--charW1));
    text-align: center;
}

.drop-message .message-box-inner {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}


[data-theme=msdos] .message-box-button {
    background: var(--background);
    padding: 0 var(--charW2);
    box-shadow: none;
}

@media (max-resolution: 1dppx) {
  /* ensure crisp icons at 1:1 native resolution, smooth images otherwise */
  img, .Visualizer-overlay, .Visualizer-analyzer, .Visualizer-spectrogram, .inline-icon {
      image-rendering: pixelated;
  }
}
